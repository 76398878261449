<header [ngClass]="{ 'border-bottom': state.pageConfig()?.headerBorderBottom }">
  <div class="container">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto">
        <app-brand
          class="cursor-pointer"
          (click)="navigate(!!state.user() ? Paths[Navigation.Profile] : [])"></app-brand>
      </div>

      <div class="col-auto">
        <div class="overflow-hidden">
          <div class="row g-lg-5 align-items-center">
            @if (state.pageConfig()?.headerActions) {
              @if (!!state.user()) {
                <div class="col-auto">
                  <app-icon class="cursor-pointer" icon="account_circle" (click)="navigate(Paths[Navigation.Profile])">
                  </app-icon>
                </div>

                <div class="col-auto">
                  <app-icon class="cursor-pointer" icon="settings" (click)="navigate(Paths[Navigation.Settings])">
                  </app-icon>
                </div>
              } @else {
                <div class="col-auto">
                  <app-icon class="d-lg-none" icon="person" (click)="navigate(Paths[Navigation.Login])"></app-icon>
                  <button
                    class="d-none d-lg-block"
                    mat-flat-button
                    color="primary"
                    (click)="navigate(Paths[Navigation.Login])">
                    {{ 'login' | translate }}
                  </button>
                </div>
              }
            }

            <div class="col-auto">
              <app-language-switcher></app-language-switcher>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
