import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '../base/base.component';
import { BrandComponent } from '../brand/brand.component';
import { LanguageSwitcherComponent } from '../language-switcher/language-switcher.component';
import { MatButtonModule } from '@angular/material/button';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule, BrandComponent, LanguageSwitcherComponent, IconComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent extends BaseComponent {}
